<template>
  <div>
    <v-alert type="info"
             dense
             dismissible
             text
             border="left"
             v-if="hasDraft"
    >
      <v-row align="center">
        <v-col class="grow no-gutters">
          {{ $t('lastUnsaved') }} {{ lastDraft.municipality }}, {{ lastDraft.location_description }}
        </v-col>
        <v-col class="shrink py-0">
          <v-btn
            outlined
            color="primary"
            @click="openProperty(lastDraft)"
          >
            {{ $t('continue') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-alert>
    <v-data-table
      :headers="headers"
      :items="getProperties()"
      :loading="getProperties().length == 0"
    >
      <template v-slot:item.localisation="item">
        <p>{{ item.item.localisation }}<span v-if="item.item.location_description">,</span></p>

        <p>{{ item.item.location_description }}</p>
      </template>
      <template v-slot:item.price="item">
        {{ price(item.item.price) }}
      </template>
      <template v-slot:item.status="item">
        <p class="small-text">{{ $t(item.item.status) }}</p>
      </template>
      <template v-slot:item.controls="props">
        <div class="d-flex">
          <v-btn class="mx-2 btn-gradient-blue" small color="pink" @click="onButtonClick(props)">
            {{ $t('showOnMap')}}
          </v-btn>
          <v-icon
            v-if="isEditable(props.item.status)"
            class="ml-2"
            small
            @click="openProperty(props.item)">
            mdi-pencil
          </v-icon>
          <v-icon
            v-if="isEditable(props.item.status)"
            class="ml-2"
            small color="red"
            @click="deleteProperty(props.item.id)">
            mdi-delete
          </v-icon>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { some } from 'lodash';

export default {
  name: 'PropertiesList',
  data: (vm) => ({
    headers: [
      { text: vm.$t('localisation'), value: 'localisation' },
      { text: vm.$t('area'), value: 'area' },
      { text: `${vm.$t('price')} PLN`, value: 'price' },
      { text: vm.$t('status'), value: 'status' },
      { text: '', value: 'controls', sortable: false },
    ],
  }),
  async created() {
    await this.fetchProperties();
  },
  computed: {
    ...mapGetters('properties', ['properties']),
    hasDraft() {
      return this.properties.length > 0 && some(this.properties, (p) => p.status === 'draft');
    },
    lastDraft() {
      const sortedProperties = [...this.properties.filter((p) => p.status === 'draft')];
      sortedProperties.sort((p1, p2) => new Date(p2.updated_at) - new Date(p1.updated_at));
      return sortedProperties.length > 0 && sortedProperties[0];
    },
  },
  methods: {
    ...mapActions('properties', ['fetchProperties', 'removeProperty']),
    getProperties() {
      if (!this.properties) return [];
      return this.properties.map((prop) => ({
        id: prop.id,
        localisation: prop.municipality,
        location_description: prop.location_description,
        area: prop.total_lots_area,
        price: prop.price,
        controls: prop.lots.length > 0 ? prop.lots[0].lot_id : null,
        status: prop.status,
      }));
    },
    isEditable(status) {
      return status === 'draft';
    },
    onButtonClick(item) {
      this.$emit('goToParcel', item.value);
    },
    openProperty(property) {
      this.$router.push({ name: 'editProperty', params: { uuid: property.id } });
    },
    deleteProperty(id) {
      this.removeProperty(id);
    },
    price(amount) {
      return Number(amount).toLocaleString();
    },
  },
};
</script>

<style scoped lang="scss">
  .small-text { font-size: 11px; }

  .v-alert {
    ::v-deep .v-icon {
      margin-top: auto;
      margin-bottom: auto;
    }

    ::v-deep .v-btn__content {
      color: #1974E4 !important;
    }

    ::v-deep v-alert__content {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
</style>

import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import deliveryAddress from '@/store/module/deliveryAddress';
import userContract from './module/userContract';
import user from './module/user';
import newPropertyForm from './module/newPropertyForm';
import properties from './module/properties';
import files from './module/files';
import announcement from './module/announcement';
import propertyAnnouncements from './module/propertyAnnouncements';
import offer from './module/offer';
import propertyOffers from './module/propertyOffers';
import contract from './module/contract';
import notifications from './module/notifications';
import companyPartner from './module/companyPartner';

Vue.use(Vuex);

/* eslint-disable dot-notation */
axios.defaults.headers.common = {};
axios.defaults.headers.common['Accept'] = 'application/json';

if (localStorage.getItem('token') !== undefined) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
}

export default new Vuex.Store({
  modules: {
    announcement,
    propertyAnnouncements,
    user,
    newPropertyForm,
    properties,
    files,
    offer,
    propertyOffers,
    contract,
    userContract,
    notifications,
    deliveryAddress,
    companyPartner,
  },
});

<template>
  <div ref="leafletMap" id="map"/>
</template>

<script>
// we need this for leaflet:
/* eslint-disable no-undef */

import uldk from '@/services/uldk-service';
import pointOnFeature from '@turf/point-on-feature';
import { eventBus } from '@/services/eventBus';
import 'leaflet.control.opacity';
import 'leaflet.control.opacity/dist/L.Control.Opacity.css';

const lotsLayer = L.layerGroup();
const previewLayer = L.layerGroup();

const s3 = process.env.NODE_ENV === 'development'
  ? 'https://fpcom-staging-backend-leaflet.s3-eu-west-1.amazonaws.com'
  : 'https://fpcom-prod-backend-leaflet.s3-eu-west-1.amazonaws.com';

export default {
  name: 'Map',
  props: [
    'parcels',
    'goToParcelOnStart',
    'studiumId',
    'mpzpList',
  ],
  data() {
    return {
      maxZoom: 20,
      map: null,
      layers: {},
      gugikLayer: {},
      renderedParcels: [],
    };
  },
  created() {
    eventBus.$on('requestArea', this.getArea);
  },
  async mounted() {
    this.map = L.map(this.$refs.leafletMap, { zoomControl: false }).setView([54.345, 18.64], 16);

    this.map.createPane('labels');
    L.control.zoom({
      position: 'topright',
    }).addTo(this.map);

    this.gugikLayer = L.WMS.source('https://integracja.gugik.gov.pl/cgi-bin/KrajowaIntegracjaEwidencjiGruntow', {
      layers: 'dzialki,numery_dzialek,budynki',
      format: 'image/png',
      version: '1.1.1',
      maxZoom: 20,
      transparent: true,
      queryable: true,
      identify: false,
    });

    const basemaps = {
      OSM: L.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}', {
        attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
        maxZoom: this.maxZoom,
        id: 'mapbox/streets-v11',
        tileSize: 512,
        zoomOffset: -1,
        accessToken: 'pk.eyJ1Ijoib3NrYXJzenVsYyIsImEiOiJjazhyNjdrMHEwMDZqM2VxZjBuemNmOXl4In0.KkoRzyO95-6t7vDToTVRJw',
      }),
      Orto: L.tileLayer.wms('https://mapy.geoportal.gov.pl/wss/service/PZGIK/ORTO/WMS/HighResolution', {
        layers: 'Raster',
        maxZoom: this.maxZoom,
      }),
    };

    const overlays = {
      Gugik: this.gugikLayer.getLayer('dzialki,numery_dzialek,budynki'),
    };

    const tileLayers = [];

    if (this.mpzpList) {
      // eslint-disable-next-line no-restricted-syntax
      for (const mpzpData of this.mpzpList) {
        // eslint-disable-next-line max-len
        tileLayers[`MPZP: ${mpzpData.planNumber} (map)`] = L.tileLayer(`${s3}/mpzp/${mpzpData.id}/tiles/{z}/{x}/{y}.png`, {
          tms: true,
          opacity: 1,
          attribution: '',
          minZoom: 13,
          maxZoom: 20,
        });
      }
    }

    basemaps.OSM.addTo(this.map);
    lotsLayer.addTo(this.map);
    previewLayer.addTo(this.map);

    L.control.layers(basemaps, { ...overlays, ...tileLayers }, { collapsed: false }).addTo(this.map);

    L.control.opacity(
      tileLayers,
      {
        label: this.$t('transparency'),
      },
    ).addTo(this.map);

    if (this.parcels) await this.handleParcelsChanged(this.parcels);
    window.addEventListener('resize', this.onResize);
  },
  beforeDestroy() {
    if (this.map && this.map.remove) {
      this.map.off();
      this.map.clearAllEventListeners();
      this.map = null;
    }
    eventBus.$off('requestArea');
    window.removeEventListener('resize', this.onResize);
  },
  watch: {
    async parcels() {
      if (!this.parcels || !this.map) return;

      const changedParcels = this.parcels.filter(
        (parcel) => !this.renderedParcels.find(
          (renderedParcel) => renderedParcel.lot_id === parcel.lot_id,
        ),
      );

      if (changedParcels.length > 0) {
        await this.handleParcelsChanged(this.parcels);
        this.renderedParcels = this.parcels.slice();
      }
    },
  },
  methods: {
    async handleParcelsChanged(data) {
      if (!data) return;
      lotsLayer.clearLayers();
      // eslint-disable-next-line no-restricted-syntax
      for (const val of Object.values(data)) {
        // eslint-disable-next-line camelcase
        const { lot_id } = val;
        // eslint-disable-next-line no-await-in-loop
        await this.tryAddingParcelGeometry(lot_id);
      }

      if (this.goToParcelOnStart) {
        if (data.length > 0) this.goToParcel(data[data.length - 1].lot_id);
      }

      this.map.invalidateSize();
    },
    async handleMpzpIds() {
      L.control.layers(tileLayers, { collapsed: false }).addTo(this.map);
    },
    async tryAddingParcelGeometry(id) {
      try {
        if (this.layers[id]) {
          lotsLayer.addLayer(this.layers[id].geoJson);
          return;
        }

        // eslint-disable-next-line no-await-in-loop
        const parcelData = await uldk.getParcelGeometry(id);
        const dataLayer = omnivore.wkt.parse(
          parcelData,
        );

        lotsLayer.addLayer(dataLayer);

        this.layers[id] = {
          geoJson: dataLayer,
          rawData: parcelData,
          showing: true,
        };
      } catch (error) {
        console.log('error adding lots to map: ', error);
      }
    },
    async tryAddingPreviewGeometry(id) {
      try {
        if (this.layers[id]) {
          previewLayer.addLayer(this.layers[id].geoJson);
          return;
        }

        // eslint-disable-next-line no-await-in-loop
        const parcelData = await uldk.getParcelGeometry(id);
        const dataLayer = omnivore.wkt.parse(
          parcelData,
        );

        previewLayer.addLayer(dataLayer);

        this.layers[id] = {
          geoJson: dataLayer,
          rawData: parcelData,
          showing: true,
        };
      } catch (error) {
        console.log('error adding lots to map: ', error);
      }
    },
    goToParcel(id) {
      if (Array.isArray(id)) {
        const layers = id.map((item) => this.layers[item].geoJson);
        this.map.fitBounds(L.featureGroup(layers).getBounds(), { maxZoom: 16 });
      } else {
        this.map.fitBounds(this.layers[id].geoJson.getBounds(), { maxZoom: 16 });
      }
    },
    async getArea(payload) {
      previewLayer.clearLayers();
      await this.tryAddingPreviewGeometry(payload.id);
      await this.goToParcel(payload.id);

      // eslint-disable-next-line no-underscore-dangle
      const geoJsonLayer = Object.keys(this.layers[payload.id].geoJson._layers)[0];
      // eslint-disable-next-line no-underscore-dangle
      const geoJson = this.layers[payload.id].geoJson._layers[geoJsonLayer];

      const pointInFeature = pointOnFeature(geoJson.feature).geometry.coordinates;
      const pointToCheck = this.map.latLngToContainerPoint({
        lat: pointInFeature[1],
        lng: pointInFeature[0],
      });

      this.gugikLayer.getFeatureInfo(
        pointToCheck,
        'latlng',
        ['dzialki', 'numery_dzialek', 'budynki'],
        (latlng, text) => {
          const parser = new DOMParser();
          const xmlDoc = parser.parseFromString(text, 'text/xml');
          try {
            const id = xmlDoc.getElementsByTagName('Layer')[0].childNodes[0].innerHTML;
            if (id !== payload.id) return;
            const area = xmlDoc.getElementsByTagName('Layer')[0].childNodes[6].innerHTML;
            if (area) {
              eventBus.$emit('lotArea', {
                id: payload.id,
                meta: {
                  parcelNumber: payload.parcelNumber,
                  regionName: payload.regionName,
                },
                area,
                error: false,
              });
            } else {
              eventBus.$emit('lotArea', {
                id: payload.id,
                meta: {
                  parcelNumber: payload.parcelNumber,
                  regionName: payload.regionName,
                },
                error: true,
              });
            }
          } catch (error) {
            eventBus.$emit('lotArea', {
              id: payload.id,
              meta: {
                parcelNumber: payload.parcelNumber,
                regionName: payload.regionName,
              },
              error: true,
            });
          }
        },
      );
    },
  },
};
</script>

<style scoped>
#map {
  width: 100%;
  height: 100%;
  min-height: 85vh;
}
</style>

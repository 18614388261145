<template>
  <div>
    <div class="align-center justify-center">
      <v-row>
        <v-col>
          {{ $t('agreement_legal_entity') }}
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="7"
        >
          <div class="radio w-100">
            <div class="d-flex align-center justify-center w-100">
              <v-radio-group
                v-model="owner_type"
                :rules="[(v) => !!v || 'Required']"
                required
                class="w-100"
              >
                <v-radio
                  :label="$t('natural_person')"
                  value="natural_person"
                  @click="step = 2"
                ></v-radio>
                <v-radio
                  :label="$t('company')"
                  value="company"
                  @click="step = 2"
                ></v-radio>
              </v-radio-group>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
    <div class="align-center justify-center" v-if="step === 2 && owner_type === 'natural_person'">
      <v-row>
        <v-col>
          {{ $t('natural_person_data') }}
        </v-col>
      </v-row>
      <v-form v-model="isDataValid">
        <v-row>
          <v-col cols="12" md="8">
            <v-text-field
              v-model="natural_person_data.first_name"
              :label="$t('first_name')"
              :rules="requiredRule"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="8">
            <v-text-field
              v-model="natural_person_data.last_name"
              :label="$t('last_name')"
              :rules="requiredRule"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="8">
            <v-text-field
              v-model="natural_person_data.pesel"
              :label="$t('pesel')"
              :rules="peselRules.concat(requiredRule)"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="8">
            <v-text-field
              v-model="natural_person_data.id_card"
              :label="$t('id_card')"
              :rules="requiredRule"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="8">
            <v-text-field
              v-model="natural_person_data.issuing_authority"
              :label="$t('issuingAuthority')"
              :rules="requiredRule"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="8">
            <v-text-field
              v-model="natural_person_data.street_name"
              :label="$t('street')"
              :rules="requiredRule"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="8">
            <v-text-field
              v-model="natural_person_data.street_number"
              :label="$t('building_number')"
              :rules="requiredRule"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="8">
            <v-text-field
              v-model="natural_person_data.postal_code"
              :label="$t('postal_code')"
              :rules="requiredRule"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="8">
            <v-text-field
              v-model="natural_person_data.city"
              :label="$t('city')"
              :rules="requiredRule"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="8">
            <v-text-field
              v-model="natural_person_data.country"
              :label="$t('country')"
              :rules="requiredRule"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="8">
            <v-text-field
              v-model="natural_person_data.phone_number"
              :label="$t('phone_number')"
              :rules="phoneRules.concat(requiredRule)"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="8">
            <div class="text-left">
              <v-btn
                :disabled="!owner_type || !isDataValid"
                class="mx-auto elevation-0"
                color="primary"
                @click="addOwner"
              >
                {{ $t('next') }}
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-form>
    </div>
    <div class="align-center justify-center" v-if="step === 2 && owner_type === 'company'">
      <v-row>
        <v-col>
          {{ $t('company_data') }}
        </v-col>
      </v-row>
      <v-form v-model="isDataValid">
        <v-row>
          <v-col cols="12" md="8">
            <v-select
              v-model="company_data.name"
              :label="$t('legal_form')"
              :items="legalForms"
              :rules="requiredRule"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="8">
            <v-text-field
              v-model="company_data.company_name"
              :label="$t('partnership_name')"
              :rules="requiredRule"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="8">
            <v-text-field
              v-model="company_data.street_name"
              :label="$t('street')"
              :rules="requiredRule"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="8">
            <v-text-field
              v-model="company_data.street_number"
              :label="$t('building_number')"
              :rules="requiredRule"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="8">
            <v-text-field
              v-model="company_data.postal_code"
              :label="$t('postal_code')"
              :rules="requiredRule"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="8">
            <v-text-field
              v-model="company_data.city"
              :label="$t('city')"
              :rules="requiredRule"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="8">
            <v-text-field
              v-model="company_data.country"
              :label="$t('country')"
              :rules="requiredRule"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="8">
            <v-text-field
              v-model="company_data.krs"
              :label="$t('krs')"
              :rules="requiredRule"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="8">
            <v-text-field
              v-model="company_data.nip"
              :label="$t('nip')"
              :rules="requiredRule"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="8">
            <v-text-field
              v-model="company_data.regon"
              :label="$t('regon')"
              :rules="requiredRule"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="8">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
              <v-text-field
                v-model="company_data.registration_authority"
                :label="$t('registration_authority')"
                :rules="requiredRule"
                v-on="on"
              />
              </template>
              <span>{{ $t('registration_authority_example') }}</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="8">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
              <v-text-field
                v-model="company_data.krs_department_number"
                :label="$t('krs_department_number')"
                :rules="requiredRule"
                v-on="on"
              />
              </template>
              <span>{{ $t('krs_department_number_example') }}</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="8">
            <v-text-field
              v-model="company_data.phone_number"
              :label="$t('phone_number')"
              :rules="phoneRules.concat(requiredRule)"
            />
          </v-col>
        </v-row>
        <div class="my-4" v-if="selectedPartnership">
          <v-row>
            <v-col cols="12" md="8">
              <v-text-field
                v-model="company_data.company_representative_person"
                :label="$t('company_representative_person')"
                :rules="requiredRule"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="8">
              <v-text-field
                v-model="company_data.function_in_management"
                :label="$t('function_in_management')"
                :rules="requiredRule"
              />
            </v-col>
          </v-row>
          <v-row v-if="this.$router.currentRoute.path !== '/userContract'">
            <v-col cols="12" md="8">
              <v-select
                v-model="company_data.property_type"
                :label="$t('property_type')"
                :items="propertyTypes"
                item-text="displayValue"
                item-value="value"
                :rules="requiredRule"
              />
            </v-col>
          </v-row>
        </div>
        <div class="my-4" v-if="!selectedPartnership">
          <CompanyPartner
            :companyPartners="this.company_partners"
            :companyRepresentingPartners="this.company_representing_partners"
            :selectedPartner="this.company_data.name"
            :key="this.company_data.name"
          />
        </div>
        <v-row>
          <v-col cols="12" md="8">
            <div class="text-left">
              <v-btn
                :disabled="!owner_type || !isDataValid"
                class="mx-auto elevation-0"
                color="primary"
                @click="addCompanyOwner"
              >
                {{ $t('next') }}
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-form>
    </div>
  </div>
</template>

<script>
import validations from '@/mixins/validations';
import CompanyPartner from '@/components/contractForm/CompanyPartner.vue';
import { partnerships, propertyTypeOptions } from '@/helpers/enums';

export default {
  name: 'OwnerData',
  components: { CompanyPartner },
  props: [
    'owner',
  ],
  created() {
    if (this.owner) {
      this.owner_type = this.owner.owner_type;
      this.step = 2;
      if (this.owner_type === 'natural_person') {
        this.natural_person_data = {
          ...this.owner,
          phone_number: this.owner.phone_number.slice(1),
        };
      } else {
        this.company_data = {
          ...this.owner,
          phone_number: this.owner.phone_number.slice(1),
        };
      }
    }
  },
  data: (vm) => ({
    step: 1,
    isDataValid: false,
    owner_type: false,
    natural_person_data: {
      first_name: null,
      last_name: null,
      pesel: null,
      street_name: null,
      street_number: null,
      postal_code: null,
      country: null,
      city: null,
      phone_number: null,
      id_card: null,
      issuing_authority: null,
    },
    company_data: {
      nip: null,
      krs: null,
      street_name: null,
      street_number: null,
      country: null,
      city: null,
      phone_number: null,
      postal_code: null,
      name: null,
      company_name: null,
      regon: null,
      registration_authority: null,
      krs_department_number: null,
      company_representative_person: null,
      function_in_management: null,
      property_type: null,
    },
    company_partners: [],
    company_representing_partners: [],
    partnerships,
    legalForms: [
      partnerships.generalPartnership,
      partnerships.professionalPartnership,
      partnerships.limitedPartnership,
      partnerships.masterLimitedPartnership,
      partnerships.limitedLiabilityPartnership,
      partnerships.simpleJointStockCompany,
      partnerships.jointStockCompany,
    ],
    propertyTypes: [
      propertyTypeOptions.ownership,
      propertyTypeOptions.perpetualUsufructRight,
    ],
    peselRules: [
      (v) => (v && v.length === 11 && /^\d+$/.test(v)) || vm.$t('invalidPesel'),
    ],
    phoneRules: [
      (v) => (v && v.length === 9 && /^\d+$/.test(v)) || vm.$t('invalidPhone'),
    ],
  }),
  computed: {
    selectedPartnership() {
      return [
        partnerships.limitedPartnership,
        partnerships.masterLimitedPartnership,
        partnerships.limitedLiabilityPartnership,
        partnerships.jointStockCompany,
        partnerships.simpleJointStockCompany,
      ].includes(this.company_data.name);
    },
  },
  methods: {
    addOwner() {
      this.$emit('ownerAdded', {
        owner: {
          ...this.natural_person_data,
          owner_type: this.owner_type,
        },
      });
    },
    addCompanyOwner() {
      this.$emit('ownerAdded', {
        owner: {
          ...this.company_data,
          owner_type: this.owner_type,
        },
        partners: !this.selectedPartnership ? this.company_partners.concat(this.company_representing_partners) : [],
      });
    },
  },
  mixins: [
    validations,
  ],
};
</script>

<style scoped lang="scss">
  ::v-deep .v-input--radio-group__input {
    flex-direction: row !important;

    & * {
      margin-bottom: 0 !important;
    }

    .v-input--selection-controls__input {
      display: none;
    }

    label {
      border: 1px solid $f-blue;
      padding: 10px 20px;
      justify-content: center;
      color: $f-blue;
      font-weight: bold;
    }

    .v-item--active {
      label {
        background-color: $f-blue;
        color: $white;
        font-weight: bold;
      }
    }

    .v-radio {
      width: 50%;
      label {
        height: 100%;
      }
    }

    .v-radio:first-child {
      label {
        border-bottom-left-radius: 15px;
        border-top-left-radius: 15px;
      }
    }

    .v-radio:not(:first-child) {
      label {
        border-bottom-right-radius: 15px;
        border-top-right-radius: 15px;
      }
    }
  }

  .slider {
    display: flex;

    &--right {
      padding-left: 30px;
      text-align: right;
    }

    &--left {
      padding-right: 30px;
    }

    input {
      width: 50%;
    }
  }

  .v-input {
    min-height: 54px;
  }

  .v-select__selection {
    padding-left: 5px !important;
  }

  .v-text-field__slot,
  .v-select__slot,
  .v-radio {
    .v-label:after {
      content: '*';
      color: red;
    }
  }

  .no-required {
    .v-label:after {
      content: '' !important;
    }
  }

  .v.v-text-field__slot,
  .v-select__slot {
    label {
      padding-left: 5px !important;
    }
  }

  .v-text-field {
    padding-top: 0 !important;
  }

  .v-slider__thumb-label {
    background-color: transparent !important;
    width: 100% !important;

    span {
      color: $black;
    }
  }

  .v-text-field .v-label {
    left: 2px !important;
    background-color: white;
  }

</style>

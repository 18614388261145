<template>
  <div id="info" class="announcement__info">
    <h5>{{ $t('propertyName')}}</h5>
    <h2 class="my-3">{{ offer.municipality }}, {{ offer.location_description }}</h2>
    <v-row>
      <v-col
        cols="6"
        md="3"
        v-if="capacity.concept_puu"
      >
        <p class="mb-1">{{ $t('conceptionPuu') }}</p>
        <p class="font-weight-bold">max. {{ capacity.concept_puu }} m<sup>2</sup></p>
      </v-col>
      <v-col
        cols="6"
        md="3"
        v-if="capacity.concept_pum"
      >
        <p class="mb-1">{{ $t('conceptionPum') }}</p>
        <p class="font-weight-bold">max. {{ capacity.concept_pum }} m<sup>2</sup></p>
      </v-col>
      <v-col
        cols="6"
        md="3"
        v-if="announcement.main_usable_area_calculation.pum"
      >
        <p class="mb-1">{{ $t('pum') }}:</p>
        <p class="font-weight-bold">
          max. {{ announcement.main_usable_area_calculation.pum }}m<sup>2</sup>
        </p>
      </v-col>
      <v-col
        cols="6"
        md="3"
        v-if="announcement.main_usable_area_calculation.puu"
      >
        <p class="mb-1">{{ $t('puu') }}:</p>
        <p class="font-weight-bold">
          max. {{ announcement.main_usable_area_calculation.puu }}m<sup>2</sup>
        </p>
      </v-col>
      <v-col
        cols="6"
        md="3"
      >
        <p class="mb-1">{{ $t('surfaceShort') }}:</p>
        <p
          class="font-weight-bold"
          v-if="isAnnouncement"
        >{{ offer.total_lots_area }}m<sup>2</sup></p>
        <p
          class="font-weight-bold"
          v-else
        >{{ property.total_lots_area }}m<sup>2</sup></p>
      </v-col>
      <v-col
        cols="6"
        md="3"
      >
        <p class="mb-1">{{ $t('formOfPossession') }}:</p>
        <template v-for="(item, index) in ownerships">
          <span
            :key="`ownership-${index}`"
            class="font-weight-bold"
          >
            {{ $t(item.ownership_type) }}
          </span>
          <span
            :key="`till-${index}`"
            class="font-weight-bold"
            v-if="item.end_date"
          >
            {{ $t('till') }} {{ item.end_date }}
          </span>
        </template>

      </v-col>
      <v-col
        cols="6"
        md="3"
      >
        <p class="mb-1">{{ $t('planningConsiderations') }}:</p>
        <p
          class="font-weight-bold text-uppercase"
        >
          {{ announcement.analysis_type }}
        </p>
        <p
          class="announcement__info--link mt-2"
          v-scroll-to="'.absorbency'"
        >
          {{ $t('details') }}
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" v-if="isAnnouncement">
        <p class="mb-1">{{ $t('localisationDetails')}}:</p>
        <p class="announcement__info--description" v-if="announcement">{{ announcement.location_description }}</p>
      </v-col>
    </v-row>
    <v-row v-if="isAnnouncement">
      <v-col cols="12">
        <v-card>
          <v-tabs
            v-model="tab"
            active-class="active"
          >
            <v-tab href="#tab-1">{{ $t('localisation')}}</v-tab>
            <v-tab-item
              value="tab-1"
              transition="fade-transition"
              reverse-transition="fade-transition"
            >
              <div class="announcement__info-maps">
                <Map
                  :parcels="offer.lots"
                  :goToParcelOnStart="true"
                  :mpzpList="mpzpList"
                />
              </div>
            </v-tab-item>
          </v-tabs>
        </v-card>
        <v-row>
          <v-col cols="12" md="5">
            <div class="pt-2 d-flex justify-space-between">
              <div class="d-flex flex-column">
                <p>{{ $t('lotsNumbers') }}:</p>
                <span
                  class="font-weight-bold"
                  v-for="(lot, index) in offer.lots"
                  :key="`lot-${index}`"
                >{{ lot.address.lot_number }}</span>
              </div>
              <div class="d-flex flex-column">
                <p>{{ $t('region')}}:</p>
                <p
                  class="font-weight-bold"
                  v-for="(lot, index) in offer.lots"
                  :key="`lot-region-${index}`"
                >{{ lot.address.region}}</p>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row class="pt-5">
          <v-col
            cols="12"
            md="7"
            id="planningConsiderations"
            v-if="announcement.has_planning_conditions"
          >
            <p class="mb-2">{{ $t('planningConsiderations') }}:</p>
            <p>
              {{ announcement.planning_conditions }}
            </p>
          </v-col>
        </v-row>
        <v-row class="pt-5" v-if="announcement.geotechnical_comments">
          <v-col cols="12" md="7" id="geotechnicalAnalysis">
            <p class="mb-2">{{ $t('geotechnicalAnalysis') }}:</p>
            <p class="font-weight-bold">
             {{ announcement.geotechnical_comments }}
            </p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Map from '@/components/Map.vue';

export default {
  name: 'GeneralInfo',
  components: { Map },
  data() {
    return {
      tab: null,
      selected: 1,
    };
  },
  props: {
    announcement: {
      type: Object,
    },
    capacity: {
      type: Object,
    },
  },
  computed: {
    ...mapGetters('announcement', ['offer', 'mpzpList', 'ownerships']),
    ...mapGetters('offer', ['property']),
    isAnnouncement() {
      return this.$route.name === 'announcement' || this.$route.name === 'announcementPreview';
    },
  },
  methods: {
    getBoolTranslate(item) {
      if (item === false) {
        return this.$t('false');
      }
      if (item === true) {
        return this.$t('true');
      }
      if (item === null) {
        return this.$t('null');
      }
      return item;
    },
    changeTab(number) {
      this.selected = number;
      const elements = document.getElementsByClassName('offer__info-maps--map');
      const element = document.getElementById(`map-${number}`);
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < elements.length; i++) {
        elements[i].classList.add('d-none');
      }
      element.classList.remove('d-none');
    },
  },
};
</script>

<style lang="scss">
  .announcement__info {
    padding: 0 0 0 15px;
    &-maps {
      &--map {
        min-height: 330px;
        img {
          width: 100%;
        }
        &-ico {
          position: absolute!important;
          left: 0;
          right: 0;
          top: 0;
          bottom: 30px;
        }
      }
      &--tab-btn {
        &-wrapper {
          display: flex;
          flex: 1 0 auto;
        }
        flex: 1 1 auto;
        cursor: pointer;
        color: $f-blue;
        text-align: center;
        margin-bottom: 5px;
        &:not(:last-child) {
          border-right: 1px solid;
        }
        &.active {
          font-weight: bold;
        }
      }
    }
    &--link {
      color: $f-blue;
      font-size: 12px;
      cursor: pointer;
    }
    &--description {
      font-size: 18px;
      font-weight: 500;
    }
    .answer {
      padding-left: 15px;
    }
    .question {
      cursor: pointer;
      &.open {
        &:after {
          content: "\25b4"
        }
      }
      &:after {
        content: "\25be"
      }
    }
  }
</style>

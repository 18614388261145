<template>
  <div>
    <v-row>
      <v-col v-if="this.selectedPartner === this.partnerships.generalPartnership">
        {{ $t('partners_personal_data') }}
      </v-col>
      <v-col v-else cols="12" md="8">
        {{ $t('partners_unlimited_liability') }}
      </v-col>
    </v-row>
    <div v-for="(data, index) in companyPartners" v-bind:key="index">
      <v-row>
        <v-col cols="12" md="4" class="py-0">
          <v-text-field
            v-model="data.first_name"
            :label="$t('partner_first_name')"
            :rules="requiredRule"
          />
        </v-col>
        <v-col cols="12" md="4" class="py-0">
          <v-text-field
            v-model="data.last_name"
            :label="$t('partner_last_name')"
            :rules="requiredRule"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="8" class="py-0">
          <v-text-field
            v-model="data.pesel"
            :label="$t('partner_pesel')"
            :rules="peselRules.concat(requiredRule)"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="4" class="py-0">
          <v-text-field
            v-model="data.street_name"
            :label="$t('partner_street')"
            :rules="requiredRule"
          />
        </v-col>
        <v-col cols="12" md="4" class="py-0">
          <v-text-field
            v-model="data.street_number"
            :label="$t('partner_street_number')"
            :rules="requiredRule"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="4" class="py-0">
          <v-text-field
            v-model="data.city"
            :label="$t('partner_city')"
            :rules="requiredRule"
          />
        </v-col>
        <v-col cols="12" md="4" class="py-0">
          <v-text-field
            v-model="data.postal_code"
            :label="$t('partner_postal_code')"
            :rules="requiredRule"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="8" class="d-flex justify-center">
          <v-btn
            class="mx-2"
            fab
            small
            color="primary"
            @click="addCompanyPartner"
          >
            <v-icon>
              mdi-plus
            </v-icon>
          </v-btn>
          <v-btn
            class="mx-2"
            fab
            small
            color="primary"
            :hidden="index === 0"
            @click="removeCompanyPartner(index)"
          >
            <v-icon>
              mdi-minus
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <div v-if="this.selectedPartner === this.partnerships.professionalPartnership" class="mt-10">
      <v-row>
        <v-col>
          {{ $t('partners_representing_data') }}
        </v-col>
      </v-row>
      <div v-for="(data, index) in companyRepresentingPartners" v-bind:key="index">
        <v-row>
          <v-col cols="12" md="4" class="py-0">
            <v-text-field
              v-model="data.first_name"
              :label="$t('partner_first_name')"
              :rules="requiredRule"
            />
          </v-col>
          <v-col cols="12" md="4" class="py-0">
            <v-text-field
              v-model="data.last_name"
              :label="$t('partner_last_name')"
              :rules="requiredRule"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="8" class="py-0">
            <v-text-field
              v-model="data.pesel"
              :label="$t('partner_pesel')"
              :rules="peselRules.concat(requiredRule)"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="4" class="py-0">
            <v-text-field
              v-model="data.street_name"
              :label="$t('partner_street')"
              :rules="requiredRule"
            />
          </v-col>
          <v-col cols="12" md="4" class="py-0">
            <v-text-field
              v-model="data.street_number"
              :label="$t('partner_street_number')"
              :rules="requiredRule"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="4" class="py-0">
            <v-text-field
              v-model="data.city"
              :label="$t('partner_city')"
              :rules="requiredRule"
            />
          </v-col>
          <v-col cols="12" md="4" class="py-0">
            <v-text-field
              v-model="data.postal_code"
              :label="$t('partner_postal_code')"
              :rules="requiredRule"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="8" class="d-flex justify-center">
            <v-btn
              class="mx-2"
              fab
              small
              color="primary"
              @click="addCompanyPartner(index,'representingPartner')"
            >
              <v-icon>
                mdi-plus
              </v-icon>
            </v-btn>
            <v-btn
              class="mx-2"
              fab
              small
              color="primary"
              :hidden="index === 0"
              @click="removeCompanyPartner(index, 'representingPartner')"
            >
              <v-icon>
                mdi-minus
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </div>
    <v-row>
      <v-col cols="12" md="8" class="py-0">
        <v-alert
          class="mt-5"
          type="error"
          v-model="companyPartnerError.isError"
          dismissible
          transition="fade-transition"
        >
          {{companyPartnerError.message}}
        </v-alert>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import validations from '@/mixins/validations';
import { mapGetters } from 'vuex';
import { partnerships } from '@/helpers/enums';

const emptyPartnerData = {
  street_name: null,
  street_number: null,
  postal_code: null,
  city: null,
  first_name: null,
  last_name: null,
  pesel: null,
};

export default {
  name: 'CompanyPartner.vue',
  props: [
    'companyPartners',
    'companyRepresentingPartners',
    'selectedPartner',
  ],
  created() {
    if (!this.companyPartners.length) this.companyPartners.push({ ...emptyPartnerData });
    if (this.selectedPartner === partnerships.professionalPartnership && !this.companyRepresentingPartners.length) {
      this.companyRepresentingPartners.push({ ...emptyPartnerData });
    }
  },
  data: (vm) => ({
    peselRules: [
      (v) => (v && v.length === 11 && /^\d+$/.test(v)) || vm.$t('invalidPesel'),
    ],
    partnerships,
  }),
  computed: {
    ...mapGetters('companyPartner', ['companyPartnerError']),
  },
  methods: {
    addCompanyPartner(index, representingPartner) {
      if (representingPartner) {
        this.companyRepresentingPartners.push({ ...emptyPartnerData });
      } else {
        this.companyPartners.push({ ...emptyPartnerData });
      }
    },
    removeCompanyPartner(index, representingPartner) {
      if (representingPartner) {
        this.companyRepresentingPartners.splice(index, 1);
      } else {
        this.companyPartners.splice(index, 1);
      }
    },
  },
  mixins: [
    validations,
  ],
};
</script>

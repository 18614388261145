import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import { get } from 'lodash';
import apiHelper from '@/services/apiHelper';

Vue.use(Vuex);
/* eslint-disable dot-notation */

export default {
  namespaced: true,
  state: {
    naturalPersonOwners: [],
    businessOwners: [],
    currentStep: 1,
    intermediationContract: null,
    contractCancelResignation: null,
    error: {
      isError: false,
      message: '',
    },
  },
  mutations: {
    ADD_NATURAL_PERSON_OWNER: (state, data) => {
      const index = state.naturalPersonOwners.findIndex((owner) => owner.id === data.id);
      if (index >= 0) {
        Vue.set(state.naturalPersonOwners, index, data);
      } else state.naturalPersonOwners.push(data);
    },
    ADD_BUSINESS_OWNER: (state, data) => {
      const index = state.businessOwners.findIndex((owner) => owner.id === data.id);
      if (index >= 0) {
        Vue.set(state.businessOwners, index, data);
      } else state.businessOwners.push(data);
    },
    REMOVE_NATURAL_PERSON_OWNER: (state, id) => {
      const index = state.naturalPersonOwners.findIndex((owner) => owner.id === id);
      if (index >= 0) {
        state.naturalPersonOwners.splice(index, 1);
      }
    },
    REMOVE_BUSINESS_OWNER: (state, id) => {
      const index = state.businessOwners.findIndex((owner) => owner.id === id);
      if (index >= 0) {
        state.businessOwners.splice(index, 1);
      }
    },
    SET_INTERMEDIATION_CONTRACT: (state, data) => {
      state.intermediationContract = data;
    },
    SET_CONTRACT_CANCEL_RESIGNATION: (state, data) => {
      state.contractCancelResignation = data;
    },
    SET_STEP: (state, step) => {
      state.currentStep = step;
    },
    SET_ERROR: (state, data) => {
      state.error = data;
    },
  },
  getters: {
    businessOwners: (state) => get(state, 'businessOwners'),
    naturalPersonOwners: (state) => get(state, 'naturalPersonOwners'),
    intermediationContract: (state) => state.intermediationContract,
    contractCancelResignation: (state) => state.contractCancelResignation,
    currentStep: (state) => state.currentStep,
    error: (state) => state.error,
  },
  actions: {
    createOrUpdateNaturalPersonOwner: async ({ commit }, payload) => {
      try {
        axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;

        // eslint-disable-next-line no-param-reassign
        if (payload.phone_number) payload.phone_number = `+${payload.phone_number}`;

        const response = await apiHelper.postOrPatch('natural_person_contract', payload);
        if (response && response.data) {
          commit('ADD_NATURAL_PERSON_OWNER', response.data);
          return response.data;
        }
      } catch (error) {
        commit('SET_ERROR', { isError: true, message: `Error: ${error.message}` });
      }
      return null;
    },
    createOrUpdateBusinessOwner: async ({ commit }, payload) => {
      try {
        axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;

        // eslint-disable-next-line no-param-reassign
        if (payload.phone_number) payload.phone_number = `+${payload.phone_number}`;

        const response = await apiHelper.postOrPatch('business_contract', payload);
        if (response && response.data) {
          commit('ADD_BUSINESS_OWNER', response.data);
          return response.data;
        }
      } catch (error) {
        commit('SET_ERROR', { isError: true, message: `Error: ${error.message}` });
      }
      return null;
    },
    fetchContracts: async ({ commit }, propertyId) => {
      try {
        axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;

        const response = await axios.get(
          `${process.env.VUE_APP_HOST_URL}/properties/${propertyId}/contracts/`,
        );

        response.data.business_contracts.forEach((contract) => {
          commit('ADD_BUSINESS_OWNER', contract);
        });

        response.data.natural_person_contracts.forEach((contract) => {
          commit('ADD_NATURAL_PERSON_OWNER', contract);
        });
      } catch (error) {
        commit('SET_ERROR', { isError: true, message: `Data fetching error: ${error.message}` });
      }
    },
    deleteNaturalPersonOwner: async ({ commit }, owner) => {
      try {
        axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;

        const { id } = owner;
        await axios.delete(
          `${process.env.VUE_APP_HOST_URL}/natural_person_contract/${id}/`,
        );

        commit('REMOVE_NATURAL_PERSON_OWNER', id);
      } catch (error) {
        commit('SET_ERROR', { isError: true, message: `Error: ${error.message}` });
      }
    },
    deleteBusinessOwner: async ({ commit }, owner) => {
      try {
        axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;

        const { id } = owner;
        await axios.delete(
          `${process.env.VUE_APP_HOST_URL}/business_contract/${id}/`,
        );

        commit('REMOVE_BUSINESS_OWNER', id);
      } catch (error) {
        commit('SET_ERROR', { isError: true, message: `Error: ${error.message}` });
      }
    },
    getIntermediationContract: async ({ commit }, propertyId) => {
      try {
        axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('token')}`;
        const response = await axios.get(`${process.env.VUE_APP_HOST_URL}/property_contract_pdf/${propertyId}/`, {
          responseType: 'blob',
        });
        if (response && response.data) {
          const blob = new Blob([response.data]);
          const pdfUrl = URL.createObjectURL(blob);
          commit('SET_INTERMEDIATION_CONTRACT', pdfUrl);
        }
      } catch (error) {
        commit('SET_ERROR', { isError: true, message: `Data fetching error: ${error.message}` });
      }
    },
    getContractCancelResignation: async ({ commit }, propertyId) => {
      try {
        axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('token')}`;
        const response = await axios.get(`${process.env.VUE_APP_HOST_URL}/contract_cancel_resignation/${propertyId}/`, {
          responseType: 'blob',
        });
        if (response && response.data) {
          const blob = new Blob([response.data]);
          const pdfUrl = URL.createObjectURL(blob);
          commit('SET_CONTRACT_CANCEL_RESIGNATION', pdfUrl);
        }
      } catch (error) {
        commit('SET_ERROR', { isError: true, message: `Data fetching error: ${error.message}` });
      }
    },
    setStep: ({ commit }, step) => {
      commit('SET_STEP', step);
    },
  },
};

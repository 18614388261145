<template>
  <v-row>
    <v-col
      cols="12"
      md="5"
      lg="5"
      order="2"
      order-md="1"
      class="d-flex align-center justify-sm-space-between"
    >
      <div class="d-flex align-center announcement__actions--action pl-lg-5">
        <v-icon color="blue">mdi-email</v-icon>
        <span class="font-blue font-weight-bold pl-lg-3">{{ $t('askQuestion') }}</span>
      </div>
      <div class="d-flex align-center announcement__actions--action pr-sm-5">
        <v-icon color="blue">mdi-comment-question</v-icon>
        <span class="font-blue font-weight-bold pl-lg-3">{{ $t('contactWithConsultant') }}</span>
      </div>
      <div class="announcement__actions--action pl-sm-5">
        <v-icon color="blue">mdi-eye</v-icon>
        <span class="font-blue font-weight-bold pl-lg-3">{{ $t('watch') }}</span>
      </div>
    </v-col>
    <v-col
      cols="12"
      md="7"
      lg="6"
      offset-lg="1"
      order="1"
      order-md="2"
      class="d-flex flex-column flex-md-row align-center justify-md-space-between"
    >
      <div class="d-flex align-center">
        <span class="announcement__actions--action-word">{{ $t('price') }}:</span>
        <span class="font-weight-bold pl-3 announcement__actions--action-price">{{ price(offer.price) }} PLN</span>
      </div>
      <div class="announcement__actions--action pr-md-5">
          <v-btn
            class="announcement__actions--action-btn"
            @click="dialog = !dialog"
          >
            $ {{ $t('makeAnOffer')}}
          </v-btn>
        </div>
    </v-col>
    <OfferForm :offerDialog="dialog" @closeDialog="closeDialog()"/>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import OfferForm from './OfferForm.vue';

export default {
  name: 'Actions',
  data: () => ({
    dialog: false,
  }),
  components: {
    OfferForm,
  },
  computed: {
    ...mapGetters('announcement', ['offer']),
  },
  methods: {
    closeDialog() {
      this.dialog = false;
    },
    price(amount) {
      return Number(amount).toLocaleString();
    },
  },
};
</script>

<style lang="scss">
  .announcement__actions {
    box-shadow: 0px -3px 6px #00000029;
    padding: 10px 0;
    position: fixed;
    bottom: 0;
    background: #fff;
    z-index: 999;
    width: 100%;
    &--action {
      cursor: pointer;
      &-word {
        font-size: 24px;
      }
      &-price {
        font-size: 38px;
        @include respond-to(sm) {
          font-size: 18px;
        }
      }
      &-btn {
        background-color: $gold!important;
      }
    }
  }
</style>

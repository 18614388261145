import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import { get } from 'lodash';
import apiHelper from '@/services/apiHelper';

Vue.use(Vuex);
/* eslint-disable dot-notation */

export default {
  namespaced: true,
  state: {
    companyPartners: [],
    error: {
      isError: false,
      message: '',
    },
  },
  mutations: {
    ADD_COMPANY_PARTNER: (state, data) => {
      const index = state.companyPartners.findIndex((partner) => partner.id === data.id);
      if (index >= 0) {
        Vue.set(state.companyPartners, index, data);
      } else state.companyPartners.push(data);
    },
    SET_ERROR: (state, data) => {
      state.error = data;
    },
  },
  getters: {
    companyPartners: (state) => get(state, 'companyPartners'),
    companyPartnerError: (state) => state.error,
  },
  actions: {
    createOrUpdateCompanyPartner: async ({ commit }, payload) => {
      try {
        axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;

        const response = await apiHelper.postOrPatch('company_partner', payload);
        if (response && response.data) {
          commit('ADD_COMPANY_PARTNER', response.data);
          return response.data;
        }
      } catch (error) {
        commit('SET_ERROR', { isError: true, message: `Error: ${error.message}` });
      }
      return null;
    },
  },
};
